import { QUERY_KEYS } from "@/enums";
import useRegister from "@/hooks/register/useRegister";
import { useProfileContext } from "@/providers/ProfileProvider";
import BasicButton from "@/ui/BasicButton";
import PCSignContent from "@/ui/PCSignInModal/components/PCSignContent";
import { useRequest } from "ahooks";
import { Form, Input, Modal, Result, Typography, message, notification } from "antd";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

import { sendForgotPasswordEmail, signIn } from "@/services/user";

import { trackEvent } from "@/utils";

import { SIGN_IN_ERROR_CODE_MAP } from "@/constants/signin";

import styles from "./index.module.less";

const { Text } = Typography;

interface PCSignInModalProps {
  open: boolean;
  title?: string;
  onCancel?: () => void;
}

const SIGN_IN_STATUS = 1;
const FORGOT_PASSWORD_STATUS = 2;
const RESET_PASSWORD_STATUS = 3;
const ACCOUND_NOT_FOUND_STATUS = 4;

const subtip = "No worries! Fill in your email and we'll send you a link to reset your password";
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const PCSignInModal: React.FC<PCSignInModalProps> = ({ open = false, onCancel }) => {
  const { refreshProfileAsync } = useProfileContext();
  const router = useRouter();
  const [messageApi, contextHolder] = message.useMessage({
    maxCount: 1,
    getContainer: () => document.getElementById("sign-in-content") || document.body,
  });
  const exposureRef = useRef(false);

  const { register } = useRegister();

  const { loading: loadingSignin, runAsync: requestSignin } = useRequest(signIn, {
    manual: true,
  });
  const { loading: forgotPasswordLoading, run: runForgotPassword } = useRequest(
    sendForgotPasswordEmail,
    {
      manual: true,
      onError: () => {
        messageApi.error("Something went wrong");
      },
      onSuccess: res => {
        if (!res?.data?.result?.registered) {
          setRenderStatus(ACCOUND_NOT_FOUND_STATUS);
        } else {
          setRenderStatus(RESET_PASSWORD_STATUS);
        }
      },
    }
  );

  const [form] = Form.useForm();
  const [renderStatus, setRenderStatus] = useState(SIGN_IN_STATUS);
  const [closeIconSrc, setCloseIconSrc] = useState("/newimages/public/close_popup.svg");

  useEffect(() => {
    if (!exposureRef.current && open) {
      trackEvent("signin_exposure", {
        channel: "default,",
      });

      exposureRef.current = true;
    }
  }, [open]);

  const handleSignIn = async (values: { email: string; password: string }) => {
    if (!values.email) {
      messageApi.open({
        icon: false,
        content: "Please provide a valid email address!",
      });
      return;
    }

    if (!emailRegex.test(values.email)) {
      messageApi.open({
        icon: false,
        content: "Please provide a valid email address!",
      });
      return;
    }

    if (!values.password) {
      messageApi.open({
        icon: false,
        content: "Please input your password!",
      });
      return;
    }

    // TRACK: track user 'ready to login by email & password'

    trackEvent("user_login", {
      channel: "Email",
      utm_source: router?.query ? router?.query[QUERY_KEYS.UTM_SOURCE] : "",
    });

    const res = await requestSignin(values);

    // TRACK: track user 'login result by email & password'
    trackEvent("user_login_success", {
      is_success: res?.data?.success ? 1 : 0,
      channel: "Email",
      email_input: values.email,
      code: res?.data?.success ? "" : res?.data?.errorCode,
      utm_source: router?.query ? router?.query[QUERY_KEYS.UTM_SOURCE] : "",
    });

    // email password signin success
    if (res?.data?.success) {
      await refreshProfileAsync();

      if (typeof onCancel === "function") {
        onCancel();
      }
    } else {
      if (typeof res?.data?.errorCode === "number") {
        const errorCode = res?.data?.errorCode;

        if (SIGN_IN_ERROR_CODE_MAP.hasOwnProperty(errorCode)) {
          notification.error({
            message: SIGN_IN_ERROR_CODE_MAP[errorCode],
          });
        } else {
          notification.error({
            message: res?.data?.errorMsg,
          });
        }
      }
    }
  };

  const renderSignInContent = () => {
    return (
      <div id={"sign-in-content"} className={styles["sign-in-content"]}>
        <PCSignContent
          onSubmitEmailPassword={handleSignIn}
          emailPasswordSubmitting={loadingSignin}
          onForgotPassword={(email?: string) => {
            if (email) {
              form.setFieldsValue({
                email,
              });
            }

            setRenderStatus(FORGOT_PASSWORD_STATUS);
          }}
        />
      </div>
    );
  };

  const onSubmitForgotPassword = async (values: any) => {
    if (!values.email) {
      messageApi.open({
        icon: false,
        content: "Please provide a valid email address!",
        style: {
          marginTop: "30vh",
        },
      });
      return;
    }
    if (!emailRegex.test(values.email)) {
      messageApi.open({
        icon: false,
        content: "Email is invalid!",
        style: {
          marginTop: "30vh",
        },
      });
      return;
    }
    await runForgotPassword(values?.email);
  };

  const renderForgotPasswordContent = () => {
    return (
      <>
        <div className={styles["forgot-password-content"]}>
          <Image src={"/newimages/public/lock.svg"} alt="lock" width={60} height={60} />
          <Typography.Title level={2} className={styles["forgot-password-content-main-title"]}>
            Forgot Password?
          </Typography.Title>
          <Typography.Paragraph className={styles["forgot-password-content-desc"]}>
            {subtip}
          </Typography.Paragraph>
        </div>

        <Form
          onFinish={onSubmitForgotPassword}
          name="forgot-password-form"
          className={styles["forgot-password-content-form"]}
          form={form}
          layout="vertical"
        >
          <Form.Item name="email">
            <Input placeholder="Email" className={styles["email-input"]} />
          </Form.Item>
          <BasicButton
            type="primary"
            htmlType="submit"
            className={styles["submit-button"]}
            loading={forgotPasswordLoading}
          >
            Sent
          </BasicButton>
        </Form>
      </>
    );
  };

  const renderResetPasswordContent = () => {
    return (
      <>
        {/* <div className={styles["reset-password-content"]}>
          <Typography.Title level={2} className={styles["reset-password-content-title"]}>
            Reset Password
          </Typography.Title>
        </div> */}
        <Result
          icon={
            <Image
              src={"/newimages/public/success.svg"}
              width={80}
              height={80}
              alt="waiting-list-success"
            />
          }
          title="Email sent successfully!"
          subTitle="We’ve sent an email to the provided address with the instructions to reset your password."
        />
      </>
    );
  };

  const renderAccountNotFoundContent = () => {
    return (
      <>
        <div className={styles["forgot-password-content"]}>
          <Image src={"/newimages/public/bad.svg"} alt="lock" width={60} height={60} />
          <Typography.Title level={2} className={styles["forgot-password-content-main-title"]}>
            Account not found
          </Typography.Title>
          <Typography.Paragraph className={styles["forgot-password-content-desc2"]}>
            We were unable to find an account linked to this email.
          </Typography.Paragraph>
        </div>

        <Form
          name="forgot-password-form"
          className={styles["forgot-password-content-form"]}
          layout="vertical"
        >
          <BasicButton
            type="primary"
            htmlType="submit"
            className={styles["submit-button"]}
            loading={forgotPasswordLoading}
            onClick={() => {
              setRenderStatus(FORGOT_PASSWORD_STATUS);
            }}
          >
            Please enter the correct email address
          </BasicButton>
          <BasicButton
            type="primary"
            className={styles["sign-up-button"]}
            onClick={() => {
              trackEvent("landing_signin_signup_click");
              register();
            }}
            block
          >
            Not a member?<Text strong>&nbsp;Sign up now</Text>
          </BasicButton>
        </Form>
      </>
    );
  };

  return (
    <>
      {contextHolder}
      <Modal
        centered
        title={null}
        open={open}
        footer={null}
        width={480}
        wrapClassName={styles["sign-in-modal"]}
        onCancel={onCancel}
        closeIcon={
          <Image
            src={closeIconSrc}
            width={24}
            height={24}
            alt="close"
            onMouseEnter={() => setCloseIconSrc("/newimages/public/close_popup.svg")}
            onMouseLeave={() => setCloseIconSrc("/newimages/public/close_popup.svg")}
          />
        }
      >
        {renderStatus === SIGN_IN_STATUS && renderSignInContent()}
        {renderStatus === FORGOT_PASSWORD_STATUS && renderForgotPasswordContent()}
        {renderStatus === RESET_PASSWORD_STATUS && renderResetPasswordContent()}
        {renderStatus === ACCOUND_NOT_FOUND_STATUS && renderAccountNotFoundContent()}
      </Modal>
    </>
  );
};

export default PCSignInModal;
