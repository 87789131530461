import useRegister from "@/hooks/register/useRegister";
import BasicButton from "@/ui/BasicButton";
import PassWordVisibilityToggle from "@/ui/PasswordVisibilityToggle";
import { Button, Form, Input, Typography } from "antd";
import type { FormProps } from "antd";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { trackEvent } from "@/utils/trace";

import styles from "./index.module.less";

const { Text } = Typography;

const PCEmailPasswordForm: React.FC<{
  onSubmitForm?: FormProps<{
    email: string;
    password: string;
  }>["onFinish"];
  submitting?: boolean;
  onForgotPassword?: (email?: string) => void;
}> = ({ onSubmitForm: onFinish, submitting, onForgotPassword }) => {
  const router = useRouter();
  const [form] = Form.useForm();

  const { register } = useRegister();

  useEffect(() => {
    form.setFieldsValue({ email: router?.query?.email });
  }, [form, router?.query?.email]);

  const renderForgotPasswordButton = () => {
    // Only forgot password in signin
    if (typeof onForgotPassword === "function") {
      return (
        <Form.Item noStyle>
          <Button
            className={styles["forgot-password"]}
            onClick={() => onForgotPassword(form.getFieldValue("email"))}
            type="link"
          >
            Forgot Password?
          </Button>
        </Form.Item>
      );
    }

    return null;
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <Form
      className={styles.joinform}
      name="basic"
      form={form}
      labelCol={{ span: 0 }}
      wrapperCol={{ span: 24 }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item name="email">
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item name="password">
        <Input.Password
          placeholder="Password"
          visibilityToggle={{ visible: passwordVisible }}
          iconRender={visible => (
            <PassWordVisibilityToggle visible={visible} onVisibleChange={setPasswordVisible} />
          )}
        />
      </Form.Item>

      <Form.Item>
        <BasicButton
          loading={submitting}
          type="primary"
          htmlType="submit"
          className={styles["sign-in-button"]}
          block
        >
          SIGN IN
        </BasicButton>
      </Form.Item>

      {renderForgotPasswordButton()}

      <BasicButton
        type="primary"
        className={styles["sign-up-button"]}
        onClick={() => {
          trackEvent("landing_signin_signup_click");

          register();
        }}
        block
      >
        Not a member?<Text strong>&nbsp;Sign up now</Text>
      </BasicButton>
    </Form>
  );
};

export default PCEmailPasswordForm;
