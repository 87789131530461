import PCEmailPasswordForm from "@/ui/PCSignInModal/components/PCEmailPasswordForm";
import { FormProps, Typography } from "antd";
import dynamic from "next/dynamic";

import styles from "./index.module.less";

const { Paragraph, Text } = Typography;

const GoogleSignIn = dynamic(() => import("@/components/Onboarding/GoogleSignIn"), {
  ssr: false,
});

const PCSignContent: React.FC<{
  onSubmitEmailPassword?: FormProps<{
    email: string;
    password: string;
  }>["onFinish"];
  onForgotPassword?: (email?: string) => void;
  emailPasswordSubmitting?: boolean;
}> = ({ onSubmitEmailPassword, emailPasswordSubmitting, onForgotPassword }) => {
  const renderSign = () => {
    return (
      <div className={styles["sign-in-form"]}>
        <GoogleSignIn />
        <Paragraph className={styles.or}>OR</Paragraph>

        <PCEmailPasswordForm
          submitting={emailPasswordSubmitting}
          onSubmitForm={onSubmitEmailPassword}
          onForgotPassword={onForgotPassword}
        />
      </div>
    );
  };

  return (
    <div className={styles.signContent}>
      <Paragraph className={styles.title}>
        Welcome back to <Text strong>Jobright</Text>
      </Paragraph>
      {renderSign()}
    </div>
  );
};

export default PCSignContent;
