import { useUpdateEffect } from "ahooks";
import Image from "next/image";
import React from "react";

type PassWordVisibilityToggleProps = {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  width?: number;
  height?: number;
};

const HIDE_IMAGE_SOURCE = "/newimages/public/hide.svg";
const DISPLAY_IMAGE_SOURCE = "/newimages/public/display.svg";

const PassWordVisibilityToggle: React.FC<PassWordVisibilityToggleProps> = ({
  visible = false,
  width = 16,
  height = 16,
  onVisibleChange,
}) => {
  const [imageSource, setImageSource] = React.useState<string>(() => {
    if (!visible) {
      return HIDE_IMAGE_SOURCE;
    }

    return DISPLAY_IMAGE_SOURCE;
  });

  useUpdateEffect(() => {
    if (!visible) {
      setImageSource(HIDE_IMAGE_SOURCE);
    } else {
      setImageSource(DISPLAY_IMAGE_SOURCE);
    }
  }, [visible]);

  const onClickVisibleChange = () => {
    onVisibleChange(!visible);
  };

  return (
    <Image
      width={width}
      height={height}
      alt="password-toggle"
      src={imageSource}
      style={{
        cursor: "pointer",
      }}
      onClick={onClickVisibleChange}
    />
  );
};

export default PassWordVisibilityToggle;
